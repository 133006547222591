.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.image-container {
  margin: 0 0;
  position: relative;
}

@media (max-width: 768px) { /* Adjust the breakpoint value as needed */
  .content {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid red;
}
.greencircle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid rgb(248, 88, 115);
}
.cross {
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url('./incorrect-icon.png');
  background-size: cover;
}
.score {
  font-size: 10px; /* Adjust the font size as needed */
}

.lives {
  font-size: 10px; /* Adjust the font size as needed */
  margin-left: 10px; /* Add some spacing between the elements */
}
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px; /* Adjust margin as needed */
}
.button-container button {
  margin-right: 10px;
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Adjust margin as needed */
}

#uploadButton {
  padding: 10px 20px;
  margin-bottom: 20px; /* Adjust margin as needed */
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.button-text {
  font-size: 16px; /* Adjust font size as needed */
}

.next-game-button {
  background-color: #f5f5f5;
  color: #333;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.next-game-button:hover {
  background-color: #e0e0e0;
}